
.container-Activity-Phone {

    .elementP-activities.elementP-img {
opacity: 0;
top: var(--topHide);
left: var(--left);

right: var(--right);



}
.elementP-activities.elementP-img-show {
    top: var(--top);
    opacity: 0.9;

}
}
