.relative {
  margin-top: 0vh;
  position: relative;
  z-index: 8;
  transition: 1s all;
  transform: translateY(0);
  .inside {
    width: 100%;
    height: 100%;
  }
}
.more-container {
  width: 100%;
  height: 90vh;
  background-color: #acbeddbe;
  position: relative;
  z-index: 7;
  transition: 1s all;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 1s all;
  .rubonHolder {
    position: absolute;
    top: 0%;
    left: 0;
  }

  .wrapper-container {
    height: 100%;
    width: 100%;
    overflow: hidden;
  }
  .container-container {
    height: 100%;
    width: 100%;
    overflow: hidden;
  }
  .slider-container {
    background-color: rgba(114, 167, 167, 0);
    display: flex;
    scrollbar-width: none;
    justify-content: center;
    align-items: center;
    .slide {
      margin: 0 0 0 2rem;
      height: 43vh;
      width: 20vw;
      min-width: 200px;
      display: flex;

      transition: 0.5s all;
    }
  }

  .rectangle {
    z-index: -1;
    height: 40vh;
    width: 0%;
    position: absolute;
    top: -20vh;
    transform: rotate(-3deg);
    transition: 1s all;
  }
}
.slider-container.grabbing {
  cursor: grabbing;
}
.more-container,
.rectangle {
  background-color: #ffffff;
}
.rubonHolder {
  overflow: hidden;
  width: 100%;
}

//     }
//     .more-container .slider-container .first-container {
//       grid-area: 1/1/12/8;
//       transform: translate(0%);

//       .slide {
//         width: 40%;
//         height: 20%;
//         max-width: 200px;
//         max-height: 230px;
//         position: relative;
//         .wrapper-card {
//           position:relative;
//         }

//       }
//       .wrapper {
//         width: 100% !important ;
//         display: flex;
//         align-items: flex-start;
//         justify-content: flex-start;
//         flex-wrap: wrap;
//         flex-direction: column;
//         transform: translate(-15px);
//       }

//       .slide {

//         top: 25%;
//         left: 0;
//       margin-left: 5%;
//       }
//       .slide2 {
//         transform: translateY(-25%);
//         align-self: flex-end;
//       }
//       .slide3 {
//         transform: translateY(-20%);
//       }
//       .slide4 {
//         transform: translateY(-50%);
//         align-self: flex-end;
//       }
//     }

//   .more-container .slider-container .second-container {
//   position: relative;
//   grid-area: 5/6/17/10;

//   transform: translate(0);
//     .slide {
//       width: 45%;
//       height: 20%;
//       max-width: 200px;
//       max-height: 230px;
//       position: relative;
//       .wrapper-card {
//         position: absolute;
//         background-color: aqua;
//       }

//     }
//     .wrapper {
//       width: 100% !important ;
//       display: flex;
//       align-items: flex-start;
//       justify-content: flex-start;
//       flex-wrap: wrap;
//       flex-direction: column;
//       transform: translate(-15px);
//     }

//     .slide {

//       top: 25%;
//       left: 0;

//     }
//     .slide6 {
//       transform: translateY(-30%);
//       align-self: flex-end;
//     }
//     .slide7 {
//       transform: translateY(-20%);
//     }
//     .slide8 {
//       transform: translateY(-50%);
//       align-self: flex-end;
//     }
//   }
// }
// @media (max-width: 400px) {
//   .more-container .slider-container
//   {.first-container .slide {
//     width: 130px;
//     height: 135px;

//     min-width: 125px;
//     max-height: 170px;
//     position: relative;

//   }
//   .second-container {
//     transform: translate(-50%,19%);

//       grid-area: 5/6/17/10;

//     .slide {
//       width: 130px;
//       height: 135px;
//       min-width: 125px;
//       max-width: 170px;

//       max-height: 170px;
//       position: relative;

//     }
//      }

// }}
// @media (min-height: 400px) and (min-width: 400px) {
//   .more-container .slider-container
//   .second-container {
//     grid-area: 10/1/20/10;
//   }
// }
// @media (min-height: 800px) {
//   .more-container .slider-container
//   .second-container {
//     grid-area: 9/1/18/10;

//    }
// }
// @media (min-height: 800px) and (max-width:400px){
//   .more-container .slider-container
//   .second-container {
//     grid-area: 6/6/12/10;

//    ;}
// }
// @media (min-height: 880px) {
//   .more-container .slider-container
//   .second-container {
//     transform: translate(-50%,90vh);}
// }
.up {
  transform: translateY(-10%);
}
@media (max-height:500px) and (min-width:810px) {
  .more-container{
    height: 145vh !important;
  }
}