
.ElementContainer {
  
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    background-color: #99b2dd6b;
    padding: 10px;
    border-radius: 20px;
    cursor:grab;
    h2 {
        margin-bottom: 0;
        margin-left:30px;
        font-family: campton ; 
        font-weight: 900;
        font-size: 2rem;
        align-self: flex-start;

    } 
    .containerSVG {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
          p {
        font-family: Quicksand;
        width: 73%;
        height: 200px;
        font-size: 1em;
        justify-self: flex-end;
        display: flex;
        align-items: center;

    }}
    .svg1 {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 35%;
        height: 100%;
        max-width: 155px;
        max-height: 100%;
        opacity: 1;
        bottom: 0;
      left: 10px;

    } 
    .svg2 {
        position: absolute;
        left: 5%;
        opacity: 0.6;
        width: 35%;
        max-height: 100%;
        min-width: 240px;
    }
    
}

.elementcontainer2 {
    .containerSVG {
        p {
        }}
}
.elementcontainer4 {
    opacity: 0 !important;
}