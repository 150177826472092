.name-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    transition: 2s all;
    h1 {
        color: #2B2118;
        font-family: cutive;
        font-weight: 10;
        margin: 0;
        font-size: 4vw;
        display: flex;
        align-items: center;
        width: 100%;

        justify-content: center;
    }
    h3 {
        margin: 0;
        color: #A8763E;
        font-family:ivymode;
        font-size: 2vw;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

    }
}