.activities-container {
    width: 100%;
    height: 500px;
    z-index: -1;
    // transform: translateX(120vh);
    opacity: 0;

    transition: 2s all 3s;
.container {
      .center {
          width: 50vw;
          height: 30vw;


      }
      background-color:#b676ca42;
      position: fixed;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
  .img-container {
      margin-top: 80px;
      width: 100%;
      height:calc(100% - 80px);
      display: flex;
      position: relative;
      justify-content: center;
      align-items: center;
      h1 {
          margin: 0;
          font-family: ivymode;
          position: absolute;
          bottom: 6vh;
          font-size: 6em;
          left: 5vw;
          color: #D89D6A;
          font-weight: 700;
          letter-spacing: 15px;
      }
      .filter {
        z-index: 5;
        background-color: #0c528028;
     
       }
      img,.filter{
          position: absolute;
          height: 150px;
          width: 200px;
          top: 2vh;
        //   right: 0;
          transform: translate(70vw);
          transition: 2s all , 1s opacity
         
          ;

      }
   
      .show {
       
                 width: 52vw;
          height:65vh;
          margin-left: auto;
          
          margin-right: auto;
          right: 50%;
          transform: translate(40%,10%);
          opacity: 1;
          z-index: -1;
          
      }
      .show:hover{
      }
      .showsmall {
          transform: translate(0);
          right: 50px;
          height: 150px;
          width: 250px;
          opacity: 1;
          background-color: #29143162;
          top: 8vh;
      }
      .moveout {
        //   animation: first 1s;
        transform: translate(-150vw,-50vh) scale(2);
        opacity: 0;
      }
      .goBack {
        //   opacity: 0;
        //   transform: translate(17vw);


      }
     
  }

  
    }
.shortcuts {
    width: 10vw;
    height: 40vh;
    margin: 10px;
    position: absolute;
    top: 20vh;
    left: 2vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family:space;
    font-weight: 700;
    font-size: 12px;
    color: #9d88a5c7;
    letter-spacing: 2px;
    background-color: #50205f00;
    border-bottom:1px solid #634B66;
    .shortcut {
        transition: 0.5s all;
    }
    .shortcut:hover{
        cursor: pointer;
        color: #f9eaff;

    }
    .highlight {
        color: #3c2047;

    }
}
.line1 {
    position: absolute;
    width: 14vw;
    margin-top: 100px;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0);
    border-right: 1px solid #634B66;
    top: 0;
    left: 0;
    pointer-events: none;
}
.title {
transform: translate(-50vw);
transition: 2s all , 0.5s opacity;
opacity: 0;
}
.show-title {
transform: translate(0vw);
opacity: 1;

}
}

        
        .textt {   position: absolute;
            transition: 1s all , 0.5s opacity;
            margin: 10px;
            width: 300px;
            height: 300px;
        bottom: 8vh;
        transform: translate(400px);
        right: 2vw;
        background-color: rgba(0, 0, 0, 0);
            display: flex;
            color: black;
            letter-spacing: 2px;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            opacity: 0;
            font-family:ivymode;
            h3 {
                color: #7da6b1;
            font-family: space;
            margin: 0;

        }
        }
        .show-text {
        transform: translate(0px);
opacity: 1;
        }
        
    
    



.cylinder  {
    z-index: 5;
    margin-top: 100px;
    position: absolute;
top: 20vh;
right: 20vw;
    transform: rotateZ(-20deg);
    transition: 2s all;

    *{
        box-sizing: border-box;
    }
    .box {
        position: relative;
        width: 230px;
        height: 150px;
        transform-style: preserve-3d;
        animation: animate 20s linear infinite;
        transition: 1.5s all ;

        span {
            margin: 20px;
position: absolute;          
  overflow: hidden;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transform-origin: center ;
            transform-style: preserve-3d;
            // border-radius: 81%/31% 26% 0% 0%;
            // border-radius:0% 0% 135% 135% / 0% 0% 255% 255%;
            display: block;
        }

// span:after, 
// span:before {
//   content:'';
//   background:#9b677d;
//   position:absolute;
//   top:-10px;
//   left:0;
//   width:100%;
//   height:20px;
//   border-radius:0% 0% 50% 50%;
// }
// span:after {
//   top:auto;
//   bottom:-10px;
//   border-radius:50% 50% 0 0 ;
// }
        .span1 {
            transform: rotateY(calc(1 * 45deg))translateZ(300px);

        }
        .span2 {
            transform: rotateY(calc( 2* 45deg)) translateZ(300px);

        }
        .span3 {
            transform: rotateY(calc( 3* 45deg))translateZ(300px);

        }
        .span4 {
            transform: rotateY(calc( 4* 45deg))translateZ(300px);

        }
        .span5 {
            transform: rotateY(calc( 5* 45deg))translateZ(300px);

        }
        .span6 {
            transform: rotateY(calc( 6* 45deg))translateZ(300px);

        } .span7 {
            transform: rotateY(calc( 7* 45deg))translateZ(300px);

        }
        .span8 {
            transform: rotateX(calc( 8* 45deg))translateZ(300px);

        }
img {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
object-fit: cover;
border-radius: 10px;
}

    }
}
@keyframes animate {
    0%  {transform: perspective(1000px) rotateY(0deg)  ;}
    100% { 
        transform: perspective(1000px) rotateY(360deg);
    }
}
.overlay1 {
    position: absolute;
    top: 70px;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #9b677d;
    transform: translateY(-120vw);

    transition: 1s all;
}
.container-cylinder {
    pointer-events: none;
    svg {
        width: 200px;
        height: 200px;
        z-index: 5;
        top: 0;
        position: absolute;
        right: 0;
        bottom: 0;
    }
    h3{
        position: absolute;
        bottom: 1vh;
        right: 5vh;
        font-family: five;
        color: rgba(137, 188, 247, 0.555);
    }
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 6;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    transition: 1s all;
    transition-delay: 2s;
    h1 {
        margin: 50px;
        font-size: 6vw;
        font-family: avenir;
        width: 50vw;
        height: 70vh;
        display: flex;
        flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #360a1ea4;
    // -webkit-text-stroke:0.01px rgb(255, 255, 255);
div {
    // white-space: nowrap; 
    span {
        color: #9b677d;
    -webkit-text-stroke:0.01px rgba(255, 255, 255, 0.658);

    }
}
    }
}
.activities-container-show {
   opacity: 1;
}
.cylinder-hide {
    opacity: 0;

}
.box-hide {
    animation: animate 5s linear infinite !important;
}
.container-cylinder-hide {
    transform: translate(-120vw);
}
@media (min-width:2000px) {
    .container-main .maintitle {
        font-size: 4vw;
    }
    .container-main .secondtitle
{
    font-size: 2.375vw;
}
    .titles {
        h1 {
        font-size: 2.5vw;
    }}
    .text{
    font-size: 1.1vw;
    }
}