@import url(https://fonts.googleapis.com/css?family=Open+Sans:700,300);
.frame {
  right: 10%;
  top: 0;
  position: absolute;
  width: 35px;
  display: none;
  z-index: 999;
  height: 100%;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.frame .center .middle:before,
.frame .center .middle:after,
.frame .center .middle {
  width: 100%;
  height: 0.19rem;
  background: #fff;
  border-radius: 0.3rem;
  box-shadow: 1px 1px 2px rgba(30, 30, 30, 0.3);
  z-index: 0;
}
.frame .center {
  position: absolute;

  width: 100%;
  height: 100%;
}
.frame .center input[type="checkbox"] {
  position: absolute;
  width: 100%;
  height: 50%;
  z-index: 1;
  top: 25%;
  opacity: 0;
}
.frame .center .middle {
  position: relative;
  top: 50%;
  transition: transform 0.3s 0s;
}
.frame .center .middle:before,
.frame .center .middle:after {
  content: "";
  position: absolute;
}
.frame .center .middle:before {
    transform: translateY(10px);
  transition: bottom 0.3s 0.3s, transform 0.3s 0s;
}
.frame .center .middle:after {
    transform: translateY(-10px);
  transition: top 0.3s 0.3s, transform 0.3s 0s;
}
.frame.active .center input[type="checkbox"] + .middle {
  transform: rotate(45deg);
  transition: transform 0.3s 0.3s;
}
.frame.active .center input[type="checkbox"] + .middle:before {
  bottom: 0;
  transform: rotate(90deg);
  transition: bottom 0.3s 0s, transform 0.3s 0.3s;
}
.frame.active .center input[type="checkbox"] + .middle:after {
  top: 0;
  transform: rotate(90deg);
  transition: top 0.3s 0s, transform 0.3s 0.3s;
}

/*# sourceMappingURL=index.css.map */
