@media only screen and (max-width: 600px) {
    .container-main .maintitle {
        font-size: 2em;
      
    }
    .container-main {
        .secondtitle {
    font-size: 1.5rem;

        }
    }
}