
.void {
    /* background-color: aqua; */
    height: 100vh;
    width: 100%;
position: fixed;
display: flex;
justify-content: center;
align-items: center;
margin: 0;
    z-index: -5;
    right: 0;
    bottom: 0;
    left: 0;
    max-width:100%; 
    top: 0;
    object-fit: cover;
  
    /* background-image: url('../images/Background.jpg'); */
}

.container-main .titles {
    top:0;
    position: fixed;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
}
.container-main .maintitle {
    font-family: nova;
   letter-spacing: 5px; 
   color: #ffffff;
   font-size: 4rem;
   z-index: 0;
   /* */
   width: 100%;
   font-weight: 100;
   display:inline;
   text-align:center;

   margin: 0;

}
.container-main .secondtitle {
    font-family: quicksand;
    letter-spacing: 5px; 
    color: white;
    font-size: 2.375rem;
    z-index: 0;
    display:inline;
    text-align:center;
    margin: 5px;

    width: 100%;
    /* top: calc(50vh - 10px); */
    font-weight: 100;
}
.container-main{
 display: flex;
 justify-content: center;
 align-items: center; 
 height: 100vh;
 max-width:100%; 
 width: 100%;
}
.overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 0;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    top: 0;
    height: 110vh;
        background-color: #00000088;
}
#myVideo {
    max-width:100%; 
    width: 100%;
}
@media only screen and (max-width: 600px) {
    .container-main .maintitle {
        font-size: 2.5rem;
      
    }
}