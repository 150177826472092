

div.biggest-container{
    height: 100px;
}
@media screen and (max-width : 600px) {
    .pic1 {
        display: none;
    }
    .transform h1 {
        font-size: 2rem;
        white-space: nowrap;
        width: fit-content;
    }
    .firstcontainer .container  {
     width: 100%;
     justify-content: center;
     align-items: center;
 }
 .firstcontainer .container .text {
     max-width: 100%;
 }
 .firstcontainer {
     .container {    
     width:100%;
     margin: 0 5px;
     display: flex;
     justify-content: center;
     align-items: flex-start;
     /* height: 100%; */
     /* background-color: #a95187; */
 
     .titles {
     display: flex;
     align-items: center;
     justify-content: center;
     width:100%;
     h1 {
         min-width: 100%;
         display: flex;
         align-items: center;
         flex-direction: column;
         justify-content: center;
     }
 }}}
 .transform p {
     max-width: 90vw;
 }
  
 }
 
@media screen and (max-width : 700px) {
    .container-cards {
     
        
        
    h1 {
        width: 100%;
       display: flex;
       align-items: center;
       justify-content: center;
        font-size: 7.5vw; 
        margin-bottom: 0;
        white-space: nowrap;
        margin: 20px 0 0 0 ;
    }
   
 }}
 @media (max-width: 800px) {
    .firstcontainer {
        .container {   
        align-items: center;}}
     
 }