.wrapperNews {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    .NewsContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
width: 200%;
height: fit-content;
overflow: hidden;
margin-top: 50px;
    
.container-elements {
    margin: 50px 0;
    transition: 1s all;
    width: 100%;
    height: 300px;
   display: flex;
   align-items: center;
   justify-content: flex-start;
    position: relative;

}

.carousel {
    position: relative;
    width: 100%;
    height: fit-content;
    background-color: rgba(6, 16, 77, 0.548);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .titlesNews {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: space-around;
        width:    100vw ;}}

}}
.arrow,.arrowLeft {
    position: absolute;
    
    top: 160px;
    z-index: 9;
    height:300px;
    cursor: pointer;
}
.arrowLeft {
    left: 0;
}

//SWIPER ------------------------------------
.swiper-slide {
   
    margin-left: 20px;
    position: relative;
    width: 30% !important;
    min-width: 500px !important ;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 20px;
    padding: 10px;
    box-sizing: border-box;
}
.swiper {
    margin: 0 !important;
}

@media (max-width : 1000px) {
    .swiper-slide {
        margin: 0 20px;
        
        min-width: 90vw !important  ;
        height: 80% !important;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        padding: 10px;
        box-sizing: border-box;
     
        h2 {
            margin-bottom: 0;
            font-family: campton ; 
            font-weight: 900;
            font-size: 1.5rem;
            align-self: flex-start;
        } 
        .containerSVG {
            width: 100%;
        flex-direction: column;
        height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
              p {
            font-family: Quicksand;
            width: 80%;
            max-height: 200px;
            font-size: 0.8em;
    
        }
        .svg1 {
            width: 20%;
            height: 50%;
            max-width: 70px;
            max-height: 100%;
        } 
  
        }
     
    }
}

    .containerText {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
.elementcontainer2 {
.containerText {
    width: 100%;
    height: 200px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin: 1em;
        p {
            height: 150px;
        }
}}

@media (max-width : 300px) {
    .swiper-slide {
        margin: 0 20px;
        
        min-width: 90vw  ;
        height: 80% !important;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        padding: 10px;
        box-sizing: border-box;
     
        h2 {
            margin-bottom: 0;
            font-family: campton ; 
            font-weight: 900;
            font-size: 1.5rem;
            align-self: flex-start;
        } 
        .containerSVG {
            width: 100%;
        flex-direction: column;
        height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
              p {
            font-family: Quicksand;
            width: 80%;
            max-height: 200px;
            font-size: 0.65em;
    
        }
        .svg1 {
            width: 20%;
            height: 50%;
            max-width: 70px;
            max-height: 100%;
        } 
  
        }
     
    }
}

    .containerText {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
.elementcontainer2 {
.containerText {
    width: 100%;
    height: 200px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin: 1em;
        p {
            height: 150px;
        }
}}

.current {
}
@media (min-width:2000px) {
    .wrapperNews .NewsContainer .container-elements {
        height: 20vw;
      
    }
    .swiper {
        height: 100%;
    }
    .ElementContainer .containerSVG p {
    font-size: 1vw;
    }
    .ElementContainer h2 {
        font-size: 2vw;
    }
    .svg1 {
        max-width: 350px !important;

    }
    .ElementContainer {
        border-radius: 100px;
    }
    .container-imgLatest .small {
        max-height:35%;
    }
    .container-imgLatest .show, .container-imgLatest img {
        top: 9%;
        height: 30vw;
    }
    .container-Latest-Activities .rubonTitle {
    min-height: 8%;
    }
    .textAct {
 bottom: 7%;
 padding: 1%;
    }
    .navbar1 li, .navbar-item {
        font-size: 1vw;
    }
    .container-info {
        h5,h1 {
            font-size: 1vw !important;

        }
      
    } 
    .container-info {
        width: 20vw;
        height: 5vw;
    .wrapper-name {
        padding: 0.7vw !important;
    }
    .wrapper-discription {
        padding: 0.7vw !important;

    }
    }
}