@media (max-width: 800px) {
  .more-container .slider-container {
    display: grid;
    grid-template-rows: repeat(20, 5%);
    grid-template-columns: repeat(10, 10%);
  }
  .more-container {
  }
  .rightArrow,
  .leftArrow {
    display: none;
  }
}

html,
body,
.wrapper-containere {
  height: 100%;
  width: 100%;
}
.slides-containere {
  transform: translateY(4%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: hidden;
  height: 100%;
}
.slidee {
  width: 20%;
  transition: 1s all;
  margin: -2.5%;
  // max-height: 270px;
  height: 45%;
  clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
}
.slidee:hover {
}
.first-containere,
.second-containere {
  min-width: 100%;
  transition: 1s all;
  transform: translate(52%, 35%);
  height: 100%;
  display: flex;
  align-items: flex-sta0rt;
  justify-content: center;
}


  @media (max-width: 800px) {
    .more-container{
      height: auto !important;
    }
    .slides-containere {
      // display: flex;
      top: 0;
      transform: translateY(calc(10vh + 5%));
      align-items: center;
      justify-content: center;
      width: 100%;
      // min-height: 100%;
      height: auto;
      flex-direction: column;
      // transform: translateY(30%);
    }
    .slidee {
      width: 30%;
      min-width: 150px;
      background-color: blue;
      min-height: 9%;
      margin: 10px;
      transition: 0.5s all;
      height: 9%;
      clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
    }
    .slideePhone.slidePhone1 {
      transform: translate(-50%);
    }
    .slideePhone.slidePhone2 {
      transform: translate(55%, -35%);
    }
    .slideePhone.slidePhone3 {
      transform: translate(-50%, -45%);
    }
    .slideePhone.slidePhone4 {
      transform: translate(55%, -75%);
    }
    .slideePhone.slidePhone5 {
      transform: translate(-55%, -85%);
    }
    .slideePhone.slidePhone6 {
      transform: translate(55%, -115%);
    }
    .slideePhone.slidePhone7 {
      transform: translate(-55%, -125%);
    }
    .slideePhone.slidePhone8 {
      transform: translate(55%, -135%);
    }
    .first-containere,
    .second-containere {
      min-width: 100%;
  position: relative;
      transform: translate(0%) !important;
      flex-direction: column;
      min-height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .second-containere {
      transform: translateY(-54%);
    }
    .more-container .rubonHolder {
      top: 0%;
    }
  }  

  @media (max-width:800px) {

    .container-info.container-info1, .container-info.container-info5{
      left: 20%;
      top: 30%;
      opacity: 0;
    }
    .container-info.container-info1-show,.container-info.container-info5-show {
      left: 50%;
      top: 30%;
      opacity: 1;
    }
    
    .container-info2,.container-info6{
      top: 13%;
      left: 30%;
      opacity: 0;
    
    }
    .container-info2-show,.container-info6-show{
      left: 40%;
      opacity: 1;
      top: 13%;
    }
    .container-info4,.container-info8{
      opacity: 0;
      left: 40%;
      top: 13%;
      
    }
    .container-info4-show, .container-info8-show{
      left: 55%;
      top: 13%;
      opacity: 1;
    }
    .container-info3, .container-info7{
      left: 32%;
      top: -5%;
      opacity: 0;
    
    }
    .container-info3-show, .container-info7-show{
      left: 42%;
      top: -5%;
      opacity: 1;
    
    }
    
  }
  @media (max-width:1050px) {
    .slidee {
      width: 22%;
      min-height: 220px;
      transition: 1s all;
      height: 15%;
      margin: -2.5%;
      max-height: 250px;
    }
  }
  @media (max-width:870px) {
    .slidee {
      width: 23%;
      min-height: 220px;
      transition: 1s all;
      height: 15%;
      margin: -2.5%;
      max-height: 220px;
    }
  }
  @media (max-height:500px) {
    .slidee {
      min-height: 220px;
    }
  } 
   @media (max-width:1250px) {
    .slidee {
      height: 35% !important;
    }
  }
  @media (min-width:300px) and (max-width: 800px) {
    .more-container {
      // height:200vh!important;
    }
  }
  @media (max-width: 800px) and (min-width:600px) {
    .more-container {
      // height:250vw!important;
    }
  }
  @media (max-width:300px) {
    .slideePhone {
  min-width: 123px !important;
      
    }
  } 
  @media (max-width:250px) {
    .slideePhone {
  min-width: 100px !important;
      
    }
  } 