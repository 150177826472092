
.rubon {
    z-index: 99999;
    width: 1000%;
    max-height: 7vh;
// background-color: #ff9771c9;
background-color: #1C3041;
    display: flex;
    align-items: center;
    justify-content: space-around;  
    transition: 0.7s all;
  h1 {
            color: white;
            margin: 5px;
           white-space: nowrap;
            font-size: 2.5vw;
            letter-spacing: 5px;
            display: flex;
            align-items: center;
            justify-content: space-around;  
            font-weight: 500;
            font-family: campton;

        }
}
     
.rubon3 {
    z-index: -1;
background-color: #D65DB1;

}

@media screen and (max-width : 600px) {
    .rubon {
        height: 60px;
        h1 {
            font-size: 7vw;
        }}
 }
 .rubon.pink {

 }