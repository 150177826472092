html, body {
    height: 100%;
}
.transform  {
    transition: 1s transform ,
    2s background-color;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: max-content;
    /* height: 500px; */
    background-color: #ffffff;
    position: relative;
    flex-direction: column;
    /* padding: 10px; */
    /* height:fit-content; */
/* background-image: url("https://www.transparenttextures.com/patterns/xv.png"); */
/* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
}
.transform .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    /* margin-left: 5vw; */

}

.transform h1 {
/* margin: 100px 50px 0;  */
font-family: ivymode;
font-weight: 300;
font-size:3rem ;
width: fit-content;
max-width: 40vw;
}
.transform span {
    color: #a95187;
}
.transform p{
    max-width: 50vw;
    font-family: avenir;
    font-weight: 100;
    line-height: 1.6;
}
.container img {
    width: 300px;
    height: 200px;
}
.content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.content .text {
    justify-self: flex-start;
}

.content .img {
    background-color: #a95187;
    background-position: center;
    background-size: cover;
    height: 500px;
    width: 300px;
    background-repeat: no-repeat;
  background-image: url("../../images/1.jpg");
}

.slogan h1{
display: flex;
justify-content: center;
align-items: center;
    margin: 0;
}
.transform h1 {
    margin-top: 0;
}

.slogan {
    display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
}

.pics {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px;
    width: 300px;
    /* overflow: hidden; */
    position: relative;
}
.pic {
    height: 500px;
    width: 300px;
    /* position: absolute; */
    top: 0;
    transition: 1s transform;
}
.pic.pic1  {
    background-image: url("../../pics/1.png");
}
.pic.pic2 {
    background-image: url("../../pics/2.png");
    transform: translate(400px);
}
.pic.pic3  {
    background-image: url("../../pics/3.png");
    transform: translate(800px);

}
.titles  {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.titles1  {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;

}
.titles .first{
    position: absolute;
    top: 10px;
   
    opacity: 1;
    transition: left 2000ms ,top 2000ms ,opacity 1200ms;
}

.container-big {
    width: 100%;
    margin-top: 100px;
    /* margin: 100px 10vh 0 10vh; */
    /* height: 2000px; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    /* background-color: #a95187; */

 
}
.firstcontainer {
    width:100%;
    /* margin: 0 5px; */
    overflow: hidden;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    /* height: 100%; */
    /* background-color: #a95187; */
}
.pic1 {
    background-image: url(../../pics/1.png);
    background-repeat: no-repeat;
    width: 30vw;
    height:70vh;

    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    
    /* position: sticky; */
    top: 350px;
    left: 0;  
    /* transform: translateY(-100px); */
background-size: cover;
max-width: 300px;
max-height: 400px;
}

.pics-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}



@media only screen and (max-width: 400px) {
  
  }



















