

@media (max-width: 800px) {
  

    .slides-containere{
   
  .container-info.container-info1{
        left:15%;
        top: 15%;
        opacity: 0;
      }
      .container-info.container-info1-showPhone {
      left: 50%;
        top:15%;
        opacity: 1;
      }
      
      .container-info.container-info2{
        top: 2%;
        left: 40%;
        opacity: 0;
      
      }
      .container-info.container-info2-showPhone{
        top: 2%;
        left:15%;
        opacity: 1;
      }
      .container-info.container-info4{
        opacity: 0;
        left: 40%;
        top: -30%;
        
      }
      .container-info.container-info4-showPhone{
        left:15%;
        top: -30%;
        opacity: 1;
      }
      .container-info.container-info3{
        left:15%;
        top: -15%;
        opacity: 0;
      
      }
      .container-info.container-info3-showPhone{
        left: 50%;
        top: -15%;
        opacity: 1;
      
      }
      .container-info.container-info5{
        left:15%;
        top: -45%;
        opacity: 0;
      
      }
      .container-info.container-info5-showPhone{
        left: 50%;
        top: -45%;
        opacity: 1;
      
      }

      .container-info.container-info6{
        left: 40%;
        top: -65%;
        opacity: 0;
      
      }
      .container-info.container-info6-showPhone{
        left:15%;
        top: -65%;
        opacity: 1;
      
      }
      .container-info.container-info7{
        left:15%;
        top: -80%;
        opacity: 0;
      
      }
      .container-info.container-info7-showPhone{
        left: 50%;
        top: -80%;
        opacity: 1;
      
      }
      .container-info.container-info8{
        left: 40%;
        top: -95%;
        opacity: 0;
      
      }
      .container-info.container-info8-showPhone{
        left:15%;
        top: -95%;
        opacity: 1;
      
      }
    }
    .container-info {
        .wrapper-info  {
            width: 55%;
        }
    }
    .slideAndText {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
  
  .slideePhone{ 
     width: 30%;
    min-width: 150px;
   
    min-height: 9%;
    margin: 10px;
    transition: 0.5s all;
    height: 9%;
    clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
    img {
      pointer-events: none;
          width: 100%;
          height: 100%;
  }
  
  }  }}