.slide {
  clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
  margin: -2rem;

  .wrapper-card {
    transition: all 0.7s;
    color: rgb(255, 255, 255);
    background-color: #441f4998;
    height: 100%;
    z-index: -2;
    clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
  }
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  img {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .image {
    transition: all 1s;
  }
  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: white;
    transition: all 1s;

    h1 {
      font-size: 2rem;
    }
  }
  .position {
    transition: all 1s;
    p {
      font-size: 1.5rem;
      margin: 0;
      font-weight: 100;
      font-family: campton;
      color: white;
    }
  }
}

.icons {
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(-40px, 30px);

  li {
    width: 32px;
    height: 32px;
    list-style-type: none;
    margin: 0;
  }
  ul {
    text-decoration: none;
    text-decoration-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
.wrapper-card {
  background-color: #000000;

  height: 20%;
  width: 60%;
  bottom: 10%;
}
.position,
.quote {
  color: rgb(0, 0, 0);
}


.slide {
  left: 0;
  position: absolute;
  z-index: 99;
}
.slide1 {
  opacity: 1;
  left: 0;
  top: 25%;
}

.slide2 {
  opacity: 1;
  top: 40%;
  left: 18%;
}
.slide3 {
  top: 25%;

  opacity: 1;
  left: 43.5%;
}
.slide4 {
  top: 40%;

  opacity: 1;
  left: 61.5%;
}
.slide5 {
  opacity: 1;
  top: 25%;
  left: 0;
}
.slide6 {
  top: 40%;
  opacity: 1;
  left: 18%;
}
.slide7 {
  opacity: 1;
  top: 25%;

  left: 43.5%;
}
.slide8 {
  top: 40%;

  opacity: 1;
  left: 61.5%;
}
.show {
}
.notShow {
  filter: blur(0.09rem);


  
}

.more-container .slider-containere .slidee:hover {
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #d3ababa2;
  z-index: 99;
  overflow: hidden;
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  border: 2px black solid;
  img.image {
    opacity: 1;
    transition: all 1s;
  }

  .title {
    transform: translate(20px);
    opacity: 1;
  }
  .position {
    transform: translate(0px);
    opacity: 1;
  }
}
.slidee.moveLeft  {
  transform: translate(-30%, -30%);

}
.slidee.moveRight {
  transform: translate(40%, -30%);

}

  .container-info {
  width: 250px;
  height: 100px;
   position: absolute;
   transition: all 1s;
    .wrapper-info {

    }
  }

.container-info1,.container-info5{
  left: 40%;
  top: -10%;
  opacity: 0;
}
.container-info1-show,.container-info5-show {
  left: 30%;
  top: -10%;
  opacity: 1;
}

.container-info2,.container-info6{
  top:30%;
  left: 50%;
  opacity: 0;

}
.container-info2-show,.container-info6-show{
  left: 40%;
  opacity: 1;
  top: 30%;
}
.container-info4,.container-info8{
  opacity: 0;
  left: 45%;
  top: 30%;
  
}
.container-info4-show, .container-info8-show{
  left: 55%;
  top:30%;
  opacity: 1;
}
.container-info3, .container-info7{
  left: 32%;
  top: -10%;
  opacity: 0;

}
.container-info3-show, .container-info7-show{
  left: 42%;
  top: -10%;
  opacity: 1;

}
.container-info {
  h1,h5 {
    margin: 5px;
    font-family: campton;
    word-break: none;

  }
  .wrapper-info {
    width: 100%;
    height: 100%;
    word-break: none;

  }
  .wrapper-name {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height:30%;
    border-radius: 10px;
    background-color: #fce8bfbb;
    margin: 0 0 10px 0;
    padding: 0 2px;
    word-break: none;

    h1 {
      font-size: calc(min(16px , 5vw));
      word-break: none;

    }
   
  }
  .wrapper-discription { 
    h5 {
      font-size: calc(min(15px , 4vw));
      padding: 0 2px;
      word-break: none;

    }
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    width: fit-content;
    height: 30%;
    border-radius: 10px;
    background-color:#8527466e;
    transition: 1s all;
    padding: 0 5px;

  }
}
.container-info1-show,.container-info2-show,.container-info3-show,.container-info4-show,
.container-info5-show ,.container-info6-show ,.container-info7-show ,.container-info8-show  {
  .wrapper-discription {
    transform: translate(20%,-10%);}
}
@media (max-width:800px) {
  .container-info{
    .wrapper-discription { 
      h5 {
        font-size: 11px 
       
  
      }
  }}}
  
