.big-container-activities {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  width: 100%;
  .rubons {
    margin-top: 5vh;
    overflow: hidden;
    top: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .container-activities {
    margin-top: 0vh;
    width: 100%;
    height: 200vh;
    min-height: 100vh;
    transition: 1s all;
    background-color: #cddddd45;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    position: relative;
    z-index: 5;
    scroll-behavior: smooth;
    .wrapper-rectangle {
      // overflow-x: hidden;
      transition: 1s all;
      position: absolute;
      top: -30vh;
      width: 100%;

      .rectangle {
        top: 30vh;
        right: 0;
        transition: 1s all;
        margin: 0;
        width: 99vw;
        height: 100vh;
        z-index: 1;
        background-color: #e6a6ca;
      }
    }

    .activities-wrapper {
      z-index: 5;
      scroll-behavior: smooth;
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 100%;
      overflow: hidden;
      height: calc(100vh + 100px);

      top: 13%;
      position: sticky;
      background-color: #99b2dd18;
      .title {
        width: 100%;
        height: 100%;

        display: flex;
        align-items: center;
        justify-content: center;
        h1 {
          font-family: campton;
          flex-direction: column;
          font-size: 4vw;
          width: 90vw;
          display: flex;
          align-items: center;
          justify-content: center;
          letter-spacing: 10px;
          color: #1c3041;
          div {
            white-space: nowrap;
          }
        }
      }
      .list {
        width: 90%;
        height: 100%;
        position: absolute;
        z-index: 5;
        margin: 50px;
        transition: 1s transform;
      }

      .element-activities {
        position: absolute;
        opacity: 0.8;
        width: 50%;
        height: 50%;

        min-width: 400px;
        border-radius: 20px;
        transition: 0.7s all;
      }
    }
  }
  .svg {
    position: absolute;
    width: 12vw;
    height: 12vw;
    transition: 1s all;
  }
  .svg1,
  .initialSvg1 {
    top: 300%;
    left: 10%;
  }
  .svg2,
  .initialSvg2 {
    top: 100%;
    right: 10%;
  }
  .svg3,
  .initialSvg3 {
    top: 100%;
  }
}
.link {
  position: absolute;
  bottom: 25vh;
  height: 7vw;
  width: fit-content;
  min-width: 20vw;
  background-color: rgba(0, 255, 255, 0);
  right: 5vw;
  overflow: hidden;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  h2 {
    transition: 0.7s all;
    transform: translateY(20vw);
    font-family: campton;
    font-size: 2.5vw;
    color: #852747;
  }
}

.element5 {
  display: none;
}
@media (max-width: 800px) {
  .big-container-activities {
    width: 100%;
    height: 100%;
  
    .container-activities {
      height: 150vh;

      .activities-wrapper {
        .list {
          width: 100%;
          margin: 0;
          .element-activities {
            transition: 0.3s all;
            max-height: 37%;
            max-width: 90%;
            min-width: 70%;
          }
        }
      }
    }
  }
}
@media (max-width: 1100px) {
  .big-container-activities {
    .container-activities {
      .activities-wrapper {
        .list {
          .element-activities {
            transition: 0.3s all;
            max-height: 37%;
            max-width: 90%;
            width: 53%;
            min-width: 400px;
          }
        }
      }
    }
  }
}
@media (max-width: 500px) {
  .big-container-activities {
    .container-activities {
      .activities-wrapper {
        height: calc(100vh + 160px);

        top: 10%;
        .list {
          .element-activities {
            transition: 0.3s all;
            height: 40%;

            max-height: 150px;
            min-width: 75%;
          }
        }
      }
    }
  }
}
@media (min-width: 1500px) {
  .big-container-activities .container-activities .activities-wrapper .element-activities {
    // max-height: 400px;
  }
  
}
@media screen {
  
}