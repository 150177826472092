html, body {
  position: relative;
  scroll-behavior: smooth;
  /* height: 100vh; */
  margin: 0;
  height: 100%;
  width: 100%;
  /* overflow-x: hidden; */
}
/* body::-webkit-scrollbar {
displa
} */
#root{
  width: 100%;
  overflow-x:unset;
}
@font-face {
  font-family: nova;
  src: url(./fonts/optima-nova-lt-light.otf);
}
@font-face {
  font-family: second;
  src: url(./fonts/bulgatti.ttf);
}
@font-face {
  font-family: classy;
  src: url(./fonts/marissa.ttf);
}
@font-face {
  font-family: ivymode;
  src: url(./fonts/ivymode.ttf);
}
@font-face {
  font-family:avenir;
  src: url(./fonts/avenir.otf);
}
@font-face {
  font-family:montreal;
  src: url(./fonts/montreal.otf);
}
@font-face {
  font-family:violante;
  src: url(./fonts/violante.ttf);
}
@font-face {
  font-family:campton;
  src: url(./fonts/NexaLight.otf);
}
@font-face {
  font-family:dosis;
  src: url(./fonts/Dosis-Light.ttf);
}
@font-face {
  font-family:cutive;
  src: url(./fonts/panterik.ttf)
}
@font-face {
  font-family:space;
  src: url(./fonts/space.ttf)
}
@font-face {
  font-family:five;
  src: url(./fonts/FiveYearsLater.ttf)
}
@font-face {
  font-family:Quicksand;
  src: url(./fonts/Quicksand.ttf)
}
@font-face {
  font-family:code;
  src: url(./fonts/code.ttf)
}