.container-Activity-Phone {
    width: 100%;
    height: 100%;
    position: relative;
    display: none;
.elementP-activities {

    position: absolute;
    opacity: 0.8;
    width: 55%;
    max-width: 200px;
    height:17%;
    max-height: 140px;
    border-radius: 20px;
    transition: 1s all;
}
}