.grabbing {
  cursor: grabbing;
}
.container-container {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: flex-start;
  justify-content: center;

  .slider-container {
    overflow: hidden;
    width: 100%;
    overflow: hidden;
    // display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    height: 100%;
    // transform: translate(70%);

    .first-container,
    .second-container {
      transition: all 1s;
      min-width: 100%;
      height: 100%;
      position: relative;
      display: flex;
      align-items: center;
      transform: translate(50%, -10%);
      justify-content: center;
    }
  }
  // .first-container{
  //   background-color: aqua;
  // }
  // .second-container{
  //   background-color: black;
  // }
  .leftArrow,
  .rightArrow {
    transform: translateY(-10%);
    width: 5%;
    height: 8%;
    z-index: 99;
    top: 50%;
    margin: 10px;
    transition: all 0.5s;
    position: absolute;
  }
  .leftArrow {
    right: 0;
  }
  .rightArrow {
    left: 0;
    opacity: 0;
  }
  .leftArrow:hover,
  .rightArrow:hover {
    cursor: pointer;
  }
  .rightArrow {
    

    z-index: 99;
    margin: 10px;
    top: 50%;
    transition: all 0.5s;

    position: absolute;
    left: 0;
  }
}
.first-container,
.second-container {
  .wrapper {
    height: 100%;
    width: 80%;
    position: relative;
  }
}
