.navbar {
  width: 100%;
  top: 0;
  height: 60px;
  background: #852747;
  /* background: linear-gradient(87deg, rgb(169,81,135) 0%, rgb(169,81,135) */
  /* 100%); */
  position: sticky;
  z-index: 5;
  transition: 1s all;
  /* background-image: url("https://www.transparenttextures.com/patterns/xv.png"); */
  /* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
}
.nav-transform {
  transform: translateY(-120px);
}
.navbar1 {
  width: 100%;
  top: 80px;
  height: 60px;
  background-color: rgba(255, 184, 184, 0);
  position: sticky;
  transform: translateY(-80px);
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 6;
}

.navbar1::after {
  display: none;
  content: "";
  position: absolute;
  top: 0;
  height: 100px;
  width: 90vw;
  // border-bottom: 1px solid rgba(255, 255, 255, 0.575);
}
.logo {
  font-family: second;
  color: rgb(255, 255, 255);
  font-size: 25px;
  z-index: 8;
  justify-self: center;
  /* margin:10%; */
  cursor: pointer;
  font-weight: 100;
  left: 10%;
  /* margin: auto; */
}
.navbar1 ul {
  /* justify-self: flex-start; */
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* width:100%; */
  color: white;
  width: 60vw;
  height: 100%;
}
.maintitle span {
  font-family: classy;
  font-weight: bolder;
  color: rgb(255, 180, 209);
}
.navbar1 li , .navbar-item {
  //   display: none;
  /* margin-left:5vw ; */
  cursor: pointer;
  font-family: quicksand;
  letter-spacing: 2px;
  color: white;
  font-size: 1rem;
}
.navbar-item,
.ul {
  /* background-color: #ffffff; */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}
.ul::after {
  content: "";
  width: 250%;
  height: 250%;
  position: absolute;
  top: var(--y);
  left: var(--x);
  // transform: translate(-50%, -50%);
  opacity: 0;
  // background: radial-gradient(
  //   circle closest-side,
  //   // rgba(255, 195, 255, 0.527),
  //   transparent
  // );
  transition: all 0.5s ease-out;
}
.ul:hover::after {
  opacity: 1;
}
.navbar1 {
  ul {
    li , .navbar-item{
      z-index: 99;
      position: relative;
      overflow: hidden;
      transition: 0.7s all;
    }
    li::after , .navbar-item::after{
      pointer-events: none;
      transition: 0.7s all;

      content: "";
      position: absolute;
      top: 0;
      width: 60%;
      bottom: 0;

      border-bottom: 2px solid rgb(255, 255, 255);
      transform: translateY(3px);
    }
    li:hover::after , .navbar-item:hover::after{
      transform: translateY(-10px);
      // border-bottom:2px solid rgb(0, 0, 0);
    }
    li:hover, .navbar-item:hover{
      transform: translateY(10px);
    }
  }
}
a {
  text-decoration: none;
  color: white;
}

.mobile-navbar {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #00000091;
  opacity: 100%;
  z-index: 1;
transform: translate(-150vw,0);
  transition: all 1s;
}
.mobile-navbar.active {
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(0,0);
}

@media (max-width: 700px) {
  .navbar1 {
    .navbar-item.li4{
      display: none !important;
    }
    ul {
    
      li::after , .navbar-item::after{
        content: "";
        border-bottom: 2px solid rgba(66, 60, 60, 0);
      }
      li:hover::after , .navbar-item:hover::after{
        transform: translateY(0px);
      }
      li:hover, .navbar-item:hover{
        transform: translateY(0px);
      }
    }
  }

  .navbar1{
  
  .navbar-list {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 70%;
    .navbar-item{
      margin-top: 10px;
      height: 10%;
      width: 100%;
      font-size: 20px !important;
      background-color:#778aa3;
    }
  }}

  .navbar {
  
    height: 70px;
  
  }
  .frame {
    display: block;
  }

  .logo {
    margin: 20px;
    font-size: 25px;
    left: 50%;
  }
  .navbar1 {
    justify-content: flex-start;
    align-items: center;
    top: 90px;
  }
}

@media (max-width: 960px) {
  li.navbar-item {
    font-size: 16px;
  }
}
